<template>
  <v-container
    class="align-stretch layout-container"
  >
    <BaseHeader
      :title="$t('performance.title')"
      :style-variant="11"
    >
      <template v-slot:design>
        <HeaderDesign />
      </template>

      <template v-slot:desc>
        <p>{{ $t('performance.subtitle') }}</p>
      </template>
    </BaseHeader>

    <PerformanceReport />
  </v-container>
</template>

<script>
import PerformanceReport from '@/modules/performanceReport'
import BaseHeader from '@/components/BaseHeader.vue'
import HeaderDesign from '@/modules/performanceReport/HeaderDesign.vue'

export default {
  components: {
    PerformanceReport,
    BaseHeader,
    HeaderDesign
  }
}
</script>
